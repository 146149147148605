import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Checkbox, Card, message } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createProject, updateProject } from '../../context/dbTransactions/projects';

const { Option } = Select;

const projectPhases = ['Phase 1', 'Phase 2', 'Phase 3'];
const projectTypes = ['Type 1', 'Type 2', 'Type 3'];
const projectStatuses = ['Pending', 'Approved', 'Rejected'];

const AddProjects = ({ project, onClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (project) {
      const { projectThumbnail, ...rest } = project;
      form.setFieldsValue(rest);
    } else {
      form.resetFields();
    }
  }, [project, form]);

  const onFinish = async (values) => {
    try {
      if (project) {
        await updateProject(project._id, values);
        message.success('Project updated successfully.');
        onClose();
      } else {
        await createProject(values);
        message.success('Project created successfully.');
        onClose();
      }
    } catch (error) {
      message.error('Failed to save project.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card title={project ? "Edit Project Details" : "Add Project Details"} style={{ maxWidth: 600, margin: 'auto' }}>
      <Form
        form={form}
        name="add_project"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Project Code"
          name="code"
          rules={[{ required: true, message: 'Please input the project code!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project Title"
          name="name"
          rules={[{ required: true, message: 'Please input the project title!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Duration (in Min)"
          name="duration"
          rules={[{ required: true, message: 'Please input the duration!' }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Phase"
          name="phase"
          rules={[{ required: true, message: 'Please select the phase!' }]}
        >
          <Select>
            {projectPhases.map((phase) => (
              <Option key={phase} value={phase}>
                {phase}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="isSubmissionProject"
          valuePropName="checked"
        >
          <Checkbox>Is Training Module</Checkbox>
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please select the type!' }]}
        >
          <Select>
            {projectTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            {projectStatuses.map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please input the description!' }]}
        >
          <ReactQuill theme="snow" />
        </Form.Item>

        <Form.Item
          label="Project Thumbnail"
          name="projectThumbnail"
        >
          <Input type="file" />
        </Form.Item>

        <Form.Item
          label="Project Video Link"
          name="projectVideoLink"
          dependencies={['type']}
          rules={[
            () => ({
              message: 'Please input the project video link!',
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project Link"
          name="projectLink"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddProjects;
