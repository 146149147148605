import React from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined, BookOutlined, LaptopOutlined, DashboardOutlined, LogoutOutlined, UnorderedListOutlined, AppleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole'); // Assume user role is stored in local storage

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'dashboard':
        navigate('/portal/dashboard');
        break;
      case 'schools':
        navigate('/portal/school');
        break;
      case 'projects':
        navigate('/portal/projects');
        break;
      case 'admins':
        navigate('/portal/admins');
        break;
      case 'submission':
        navigate('/portal/submission');
        break;
      case 'schoolVeri':
        navigate('/portal/pending-schools');
        break;
      case 'manageAppUsers':
        navigate('/portal/app-users');
        break;
      case 'logout':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    // Clear cookies
    Cookies.remove('row-auth'); // Replace 'your-cookie-name' with the actual cookie name

    // Remove token from local storage
    localStorage.removeItem('token');

    // Redirect to login page
    navigate('/portal/login');
  };

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={['dashboard']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        onClick={handleMenuClick}
      >
        <Menu.Item icon={<DashboardOutlined />} key="dashboard">Dashboard</Menu.Item>
        <SubMenu key="sub1" icon={<BookOutlined />} title="Schools">
          <Menu.Item key="schools">Manage Schools</Menu.Item>
          <Menu.Item key="schoolVeri">School Verification</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<LaptopOutlined />} title="Projects">
          <Menu.Item key="projects">Projects</Menu.Item>
        </SubMenu>
        <SubMenu key="sub4" icon={<UnorderedListOutlined />} title="Submissions">
          <Menu.Item key="submission">Manage Submissions</Menu.Item>
        </SubMenu>
        <SubMenu key="usersapp" icon={<AppleOutlined />} title="App Users">
          <Menu.Item key="manageAppUsers">Manage App Users</Menu.Item>
        </SubMenu>
        {userRole === 'superadmin' && (
          <SubMenu key="sub3" icon={<UserOutlined />} title="Admin">
            <Menu.Item key="admins">Manage Admin</Menu.Item>
          </SubMenu>
        )}
        <Menu.Item icon={<LogoutOutlined />} key="logout">Logout</Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
