const appConfig = {
    "name": "My Certificate",
    "logo": "/img/logo.png",
    "apiBaseUrl": "https://api.roboticsonwheels.in/api/v1",
    "theme":"#FFB516",
    "appAPIBaseURL" : "https://api.roboticsonwheels.in/app/v1",
    "appPort" : 3000,
    "workerURL" : "https://api.roboticsonwheels.in/api"
}

export default appConfig;
