import axios from 'axios';
import appConfig from "../../lib/config";

const workerURL = `${appConfig.workerURL}/ROBONIST_BUCKET`;
export async function deleteStorageFile(folderName, fileKey) {
    try {
        const response = await axios.delete(`${workerURL}/${folderName}/${fileKey}`);
        console.log(`File ${fileKey} deleted successfully from folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function updateStorageFile(folderName, fileKey, fileBuffer, fileType) {
    const formData = new FormData();
    const blob = new Blob([fileBuffer], { type: fileType });
    formData.append('file', blob, fileKey);
    formData.append('folder', folderName);
    try {
        const response = await axios.post(`${workerURL}/${fileKey}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(`File ${fileKey} updated successfully in folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error updating storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function getStorageFile(folderName, fileKey) {
    try {
        const response = await axios.get(`${workerURL}/${folderName}/${fileKey}`, {
            responseType: 'arraybuffer', // for handling binary data
        });
        console.log(`File ${fileKey} retrieved successfully from folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error retrieving storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function listStorageFilesInFolder(folderName) {
    try {
        const response = await axios.get(`${workerURL}/${folderName}`);
        console.log(`Files in folder ${folderName} listed successfully`);
        return response.data;
    } catch (error) {
        console.error('Error listing files in folder:', error.response?.data || error.message);
        throw error;
    }
}

export async function listAllStorageFiles() {
    try {
        const response = await axios.get(workerURL);
        console.log('All files listed successfully');
        return response.data;
    } catch (error) {
        console.error('Error listing all files:', error.response?.data || error.message);
        throw error;
    }
}
